<template>
  <div>
    <div class="border-b p-3 rounded-t bg-gray-100">
      <h3 class="text-lg text-gray-900">
        {{ questionData.uuid ? 'Modification' : 'Création' }} d'une question
      </h3>
    </div>
    <div class="space-y-6 bg-white sm:p-6">
      <t-input-group label="Type">
        <t-select
          placeholder="Choisissez un type"
          :options="types"
          v-model="questionData.type.uuid"
          text-attribute="name"
          value-attribute="uuid"
        />
      </t-input-group>

      <t-input-group label="Catégorie">
        <t-select
          placeholder="Choisissez une Catégorie"
          :options="categories"
          v-model="questionData.category.uuid"
          value-attribute="uuid"
        />
      </t-input-group>

      <t-input-group label="Titre">
        <t-input
          v-model="questionData.title"
          type="text"
          placeholder="Titre de la question"
        />
      </t-input-group>

      <t-input-group label="Réponse" class="mb-0">
        <quill-editor
          ref="myQuillEditor"
          v-model="questionData.response"
          :options="quillOptions"
        />
        <!-- <p class="text-sm text-gray-500">
          Pour ajouter un lien vers une question ou un article, veuillez utiliser cette syntaxe dans le texte du lien:
          <b>/ressource/identifiant-unique</b>.<br/>
          Exemple: <b>/article/123</b> ou <b>/question/456</b>.
        </p> -->
      </t-input-group>

      <t-input-group label="Mots-clés">
        <vue-tags-input
          v-model="currentKeyword"
          :tags="questionKeywords"
          @tags-changed="updateTags"
          allow-edit-tags
          class="w-full max-w-none"
          add-from-paste
          :separators="[';', ',']"
          :add-on-key="[13, 188, ';']"
        />
      </t-input-group>

      <div class="grid grid-cols-2 gap-3">
        <t-input-group
          label="Documents"
        >
          <file-pond
            name="questionDocuments"
            ref="pond"
            label-idle="Déposer vos fichiers ici"
            allow-multiple
            :allow-paste="false"
            :files="questionFiles"
            @addfile="addFile"
            @removefile="deleteFile"
            credits=""
          />
        </t-input-group>
      </div>
    </div>

    <div class="bg-gray-50 px-4 py-3 flex gap-3 justify-between border-t border-t-gray-300">
      <div class="flex gap-3 items-center">
        <label for="active">Actif</label>
        <t-toggle
          class="ml"
          v-model="questionData.is_enabled"
          :value="true"
          :uncheckedValue="false"
          :checked="questionData.is_enabled || true"
        />
      </div>
      <div class="flex gap-3">
        <t-button
          v-if="questionData.uuid"
          class="m-0"
          @click.prevent="deleteQuestion"
          variant="error"
        >
          Supprimer
        </t-button>
        <t-button
          @click.prevent="saveQuestion"
          variant="success"
        >
          Sauvegarder
        </t-button>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import VueTagsInput from '@johmun/vue-tags-input';
import { quillEditor } from 'vue-quill-editor'

import vueFilePond from 'vue-filepond';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import FilePondPluginImageCrop from 'filepond-plugin-image-crop';

const FilePond = vueFilePond(
  FilePondPluginFileValidateType,
  FilePondPluginImagePreview,
  FilePondPluginImageCrop
);


import { http } from '@/services/api.service'
import { format } from 'date-fns'
import French from 'vue-tailwind/dist/l10n/fr'

import PageHeader from '@/components/PageHeader'
import PageContent from '@/components/PageContent.vue'
import { formatWithOptions } from 'date-fns/fp';

export default {
  name: 'mvp-questions-form',
  components: {
    PageHeader,
    PageContent,
    quillEditor,
    FilePond,
    VueTagsInput
  },
  data () {
    return {
      quillOptions: {
        modules: {
          toolbar: [
            ['bold', 'italic'],
            [
              // { list: 'ordered' },
              { list: 'bullet' }
            ],
            ['link']
          ]
        }
      },
      questionFiles: [],
      currentKeyword: '',
      questionKeywords: [],
      thumbnailFile: null,
      thumbnailUrl: null,
      document_file: null,
      thumbnail: null,
      files: [],
      storageUrl: process.env.VUE_APP_STORAGE_URL,
      filesToAdd: [],
      locale: French,
      categories: [],
      types: []
    }
  },
  props: [
    'question',
    'questionKey'
  ],
  mounted () {
    this.getCategories()
    this.getTypes()
    this.getQuestion()
  },
  beforeMount: async function () {
    if (this.$route.params.uuid) {
      await http.get(`/faq/${this.$route.params.uuid}`).then(res => {
        this.questionData = res.data
        this.questionUniqueKey = res.data.uuid
        console.log('question', this.question)
      }).catch(err => {
        let message
        if (err && err.response.status === 404) {
          message = 'La question demandée est introuvable'
        } else {
          message = 'Erreur de chargement de la question'
        }

        this.$buefy.notification.open({
          duration: 3000,
          message,
          position: 'is-bottom-left',
          type: 'is-warning',
          hasIcon: true,
          icon: 'server'
        })
      })
    } else {
      this.questionUniqueKey = this.questionKey;
      this.questionData = this.question;
    }
    if ( ! this.question) {
      this.questionData.category = {
        uuid: null
      }
      this.questionData.type = {
        uuid: null
      }
    }
  },
  methods: {
    getQuestion () {
      this.questionKeywords = []
      if (this.questionData.keywords) {
        this.questionData.keywords.forEach(keyword => {
          this.questionKeywords.push({
            text: keyword
          })
        })
      }
      if (this.questionData.files) {
        this.questionData.files.forEach(file => {
          this.questionFiles.push({
            source: file.url,
            options: {
              type: 'local',
              file: {
                name: file.name,
                size: file.size
              },
              metadata: {
                uuid: file.uuid
              }
            }
          })
        })
      }
    },
    getCategories () {
      http.get('/faq/category').then(({ data }) => {
        this.categories = data
      }, err => {
        console.log(err)
      })
    },
    getTypes () {
      http.get('/faq/type').then(({ data }) => {
        this.types = data
      }).catch(error => {
        console.log(error)
      })
    },
    parser (date) {
      return parse(date)
    },
    saveQuestion () {
      const formData = new FormData()
      const isUpdate = !!this.questionData.uuid
      console.log('isUpdate', isUpdate)
      const { thumbnail_file, ...data } = this.question
      const postUrl = '/faq' + (isUpdate ? `/${this.questionData.uuid}` : '')
      for (const prop in data) {
        formData.append(prop, data[prop])
      }
      formData.delete('keywords')
      if (this.questionData.keywords && this.questionData.keywords.length) {
        this.questionData.keywords.forEach(keyword => {
          formData.append('keywords[]', keyword)
        })
      }

      if (!formData.get('is_enabled')) {
        formData.append('is_enabled', true)
      }


      formData.append(`type[uuid]`, this.questionData.type.uuid)
      formData.append(`category[uuid]`, this.questionData.category.uuid)

      if (!isUpdate) { // creation only, upload file
        if (this.thumbnail) {
          formData.append('thumbnail', this.thumbnail)
        }
        this.files.map(file => {
          formData.append('files[]', file)
        })

        http.post(postUrl, formData).then(res => {
          this.$emit('refresh')
          this.$parent.$modal.hide('question-form')
          this.$notify({
            group: 'maviepro-success',
            text: 'Question enregistrée.'
          })
        }).catch(err => {
          this.$notify({
            group: 'maviepro-error',
            text: err.response.data
          })
        })
      } else {
        http.post(postUrl, formData).then(res => {
          this.$emit('refresh')
          this.$parent.$modal.hide('question-form')
          this.$notify({
            group: 'maviepro-success',
            text: 'Question mise à jour.'
          })
        }).catch(err => {
          console.log(err)
          this.$notify({
            group: 'maviepro-error',
            text: err.response.data
          })
        })
      }
    },
    deleteQuestion () {
      this.$dialog.confirm({
        title: 'Supprimer cette question ?',
        text: 'Cette action ne peut pas être annulée.',
        icon: 'warning',
        cancelButtonText: 'Annuler',
        okButtonText: 'Supprimer'
      }).then((result) => {
        if (result.isOk && result.hideReason === 'ok') {
          http.delete(`/faq/${this.questionUniqueKey}`).then(res => {
            this.$notify({
              group: 'maviepro-success',
              text: 'Question supprimée.'
            })
            this.$emit('refresh')
            this.$parent.$modal.hide('question-form')
          }).catch(err => {
            console.log(err)
            this.$notify({
              group: 'maviepro-error',
              text: 'Problème lors de la suppression, veuillez contacter un administrateur.'
            })
          })
        }
      })
    },
    notify () {
      http.post(`/faq/${this.questionData.uuid}/notify`).then(res => {
        this.$notify({
          group: 'maviepro-success',
          text: 'Question notifiée.'
        })
        this.$router.push({ path: '/app/questions' })
      }).catch(err => {
        console.log(err)
        this.$notify({
          group: 'maviepro-error',
          text: 'Problème lors de la notification, veuillez contacter un administrateur.'
        })
      })
    },
    deleteFile (error, file) {
      if (!this.questionData.uuid || !file.getMetadata('uuid')) {
        return false
      }
      const fileUuid = file.getMetadata('uuid')
      http
        .delete(`/faq/${this.questionData.uuid}/file/${fileUuid}`)
        .then(({ data }) => {
          this.questionData.files = this.questionData.files.filter(doc => doc.uuid !== fileUuid)
        }).catch(err => {
          console.log(err)
        })
    },
    addFile (error, file) {
      if (file && file.file) {
        this.files.push(file.file)
      }
      // creation, fill only
      if ( ! this.questionData.uuid) {
        return false
      }
      if (error) return false
      // update: upload file now
      const formData = new FormData()
      if (file && file.file) {
        formData.append('files[]', file.file)
      }
      http.post(`/faq/${this.questionData.uuid}/file`, formData).then(({ data }) => {
        this.questionData.files = [...this.questionData.files, ...data]
        this.filesToAdd = []
      }).catch(err => {
        this.filesToAdd = []
        console.log(err)
      })
    },
    updateTags (tags) {
      this.questionData.keywords = tags.map(tag => tag.text)
    }
  }
}
</script>

<style lang="scss" scoped>

.thumbnail-preview {
  width: 128px;
  height: 128px;
  box-shadow: 1px 2px 5px 0 #999;
  border-radius: 2px;

}

.file-name {
  border: 1px solid #999 !important;
}

.documents-list li .columns {
  display: flex;
  align-items: center;

  a:hover,
  .remove:hover {
    cursor: pointer;
  }
}

.thumbnail .field-body .img {
  position: relative;
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;

  .remove {
    position: absolute;
    top: -0.75rem;
    right: -0.75rem;
    cursor: pointer;
  }
}
</style>
